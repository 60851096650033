export default function(Vue) {
  new Vue({
    el: '#dora-seo-description',

    data: {
      isExpand: false,
    },

    methods: {
      toggleDescription() {
        this.isExpand = !this.isExpand;
      },
    },
  });
}
